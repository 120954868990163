import React from "react"
import {Popover} from "antd"
import useTranslation from 'next-translate/useTranslation'

// Models
import {AuthorizedHeaderProps} from "./index"

// Components
import Menu from "../Header/components/Menu"
import Header from "../Header/Header"
import Logo from "../Logo"
import LiveDemoLabel from "../LiveDemoLabel";
import SettingsModal from "../SettingsModal"

// Constants
import {Role} from "../../constants"

/**
 * @param props
 * @returns {JSX.Element}
 */
export default function AuthorizedHeader(props: AuthorizedHeaderProps) {
    const {className, toggleSettingsModal, user, signOut, isEcgViewer = false} = props;
    const { t } = useTranslation();

    const settingsContent = () => (
        <div className="settings-container">
            <button onClick={signOut}>
                {t('common:sign_out')}
            </button>
        </div>
    );

    if (isEcgViewer) {
        return null;
    }

    return (
        <Header className={className}>
            <Menu/>
            <div className="container">
                <div className="logo-container">
                    <Logo/>
                </div>
                <LiveDemoLabel/>
                <div className="right-menu-container">
                    {user.role === Role.DOCTOR ? (
                        <div className="right-menu-item" onClick={toggleSettingsModal}>
                            <img
                                width={20}
                                height={20}
                                alt="Setting"
                                src="/static/assets/settings.svg"
                            />
                            <span className="right-menu-label">
                            {t('common:settings')}
                        </span>
                        </div>
                    ) : (
                        <Popover placement="bottom" content={settingsContent()} trigger="hover" arrowPointAtCenter>
                            <div className="right-menu-item">
                                <img
                                    width={20}
                                    height={20}
                                    alt="Setting"
                                    src="/static/assets/settings.svg"
                                />
                                <span className="right-menu-label">
                                    {t('common:settings')}
                                </span>
                            </div>
                        </Popover>
                    )}
                </div>
            </div>
            <SettingsModal/>
        </Header>
    )
}
