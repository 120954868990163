
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React from "react"
import {Store} from "redux"
import {Provider} from "react-redux"
import App from "next/app"
import withRedux from "next-redux-wrapper"
import {ThemeProvider} from "styled-components"
import {appWithTranslation} from 'next-i18next';
import * as Sentry from "@sentry/browser"

// Store
import createStore from "../src/store"

// Components
import CoreLayout from "../src/components/layouts/CoreLayout"
import GlobalStyleSheet from "../src/components/GlobalStyleSheet"

// Theme
import Theme from "../src/theme/Theme"

interface AppProps {
    store: Store
}

if (process.env.NODE_ENV === "production") {
    Sentry.init({dsn: "https://842dc387c9c044309ba877ab85dc172b@sentry.io/1782775"});
}

class DashboardApp extends App<AppProps> {

    componentDidCatch(error, errorInfo) {
        if (process.env.NODE_ENV === "production") {
            Sentry.withScope((scope) => {
                Object.keys(errorInfo).forEach((key) => {
                    scope.setExtra(key, errorInfo[key]);
                });

                Sentry.captureException(error);
            });
        }

        super.componentDidCatch(error, errorInfo);
    }

    render() {
        const {Component, pageProps, store} = this.props;

        return (
            <React.Fragment>
                <GlobalStyleSheet/>
                <Provider store={store}>
                    <ThemeProvider theme={Theme}>
                        <CoreLayout>
                            <Component {...pageProps} />
                        </CoreLayout>
                    </ThemeProvider>
                </Provider>
            </React.Fragment>
        );
    }
}

const __Page_Next_Translate__ = withRedux(createStore)(appWithTranslation(DashboardApp));


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  