import {createReducer, getType} from "typesafe-actions"

// Models
import {RecordsStateInterface} from "../../models"

// Constants
import {INITIAL_RECORDS_PAGE} from "../../constants"

// Actions
import * as a from "../actions"
import { signOutSuccess } from "../../../authorization/store/actions"
import * as singleRecordActions from "../../../single-record/store/actions"

const InitialRecordsState: RecordsStateInterface = {
    recordsById: {},
    recordsIds: [],
    recordsFetching: true,
    moreFetching: false,
    total: 0,
    totalPages: 1,
    sort: "created,desc",
    size: 20,
    currentPage: INITIAL_RECORDS_PAGE,
};

const recordsReducer = createReducer(InitialRecordsState, {
    [getType(a.fetchRecordsAsync.request)]: state => ({
        ...state,
        recordsFetching: true,
    }),
    [getType(a.fetchRecordsAsync.success)]: (state, {payload}) => ({
        ...state,
        recordsById: {
            ...payload.recordsById,
            ...state.recordsById,
        },
        recordsIds: payload.recordsIds,
        recordsFetching: false,
    }),
    [getType(a.fetchRecordsAsync.failure)]: state => ({
        ...state,
        recordsFetching: false,
    }),
    [getType(a.fetchMoreAsync.request)]: state => ({
        ...state,
        moreFetching: true,
        currentPage: state.currentPage + 1,
    }),
    [getType(a.fetchMoreAsync.success)]: (state, {payload}) => ({
        ...state,
        recordsById: {
            ...state.recordsById,
            ...payload.recordsById,
        },
        recordsIds: [
            ...state.recordsIds,
            ...payload.recordsIds,
        ],
        moreFetching: false,
    }),
    [getType(a.fetchMoreAsync.failure)]: state => ({
        ...state,
        moreFetching: false,
    }),
    [getType(a.setTotal)]: (state, {payload}) => ({
        ...state,
        total: payload.totalElements,
        totalPages: payload.totalPages,
    }),
    [getType(a.setPage)]: (state, {payload}) => ({
        ...state,
        currentPage: payload.page,
    }),
    [getType(a.mergeRecords)]: (state, {payload}) => ({
        ...state,
        recordsById: {
            ...payload.recordsById,
            ...state.recordsById,
        },
    }),

    // From single-record
    [getType(singleRecordActions.fetchHistogramChartDataAsync.success)]: (state, {payload: {id, data}}) => ({
        ...state,
        recordsById: {
            ...state.recordsById,
            [id]: {
                ...state.recordsById[id],
                histogramChartData: data,
            }
        },
    }),
    [getType(singleRecordActions.fetchSummaryByIdAsync.success)]: (state, {payload: summary}) => ({
        ...state,
        recordsById: {
            ...state.recordsById,
            [summary.id]: {
                ...state.recordsById[summary.id],
                ...summary,
            },
        },
    }),
    [getType(singleRecordActions.fetchRawSignalByIdAsync.success)]: (state, {payload: {id, rawSignals}}) => ({
        ...state,
        recordsById: {
            ...state.recordsById,
            [id]: {
                ...state.recordsById[id],
                rawSignals,
            },
        },
    }),
    [getType(singleRecordActions.markAsReviewed.success)]: (state, {payload: id}) => ({
        ...state,
        recordsById: {
            ...state.recordsById,
            [id]: {
                ...state.recordsById[id],
                reviewed: true,
            },
        },
    }),
    [getType(singleRecordActions.fetchDetailsAsync.success)]: (state, {payload: {id, data}}) => ({
        ...state,
        recordsById: {
            ...state.recordsById,
            [id]: {
                ...state.recordsById[id],
                details: data,
            }
        }
    }),

    // From auth
    [getType(signOutSuccess)]: () => InitialRecordsState,
});

export default recordsReducer
