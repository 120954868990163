import React from "react"
import styled from "styled-components"
import {Layout as AntLayout} from "antd"

function EmptyLayout(props) {
    return (
        <React.Fragment>
            <AntLayout.Content className={props.className}>
                {props.children}
            </AntLayout.Content>
        </React.Fragment>
    )
}

export default styled(EmptyLayout)`
  background: url("../../../../static/assets/background/bg.png") top left/cover no-repeat;
  
  @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url("../../../../static/assets/background/bg@2x.png");
  }
`
