import {Dispatch} from "redux"
import {connect} from "react-redux"
import {NextComponentType} from "next"

// Actions
import * as a from "../store/actions"

// Models
import {SignInCredentialsInterface, SignUpRequestDto} from "../models"

export type WithAuthProps = {
    signOut(): void
    fetchCurrentUser(): void
    resetPassword(email: string): void
    confirmAccount(token: string): void
    resendConfirmation(email: string): void
    signUp(requestDto: SignUpRequestDto): void
    signIn(credentials: SignInCredentialsInterface): void
    changePassword(token: string, password: string): void
}

export default function withAuth(BaseComponent: NextComponentType) {
    return connect(
        null,
        (dispatch: Dispatch) => ({
            signOut: () => dispatch(a.signOut()),
            fetchCurrentUser: () => dispatch(a.fetchCurrentUser()),
            resetPassword: (email: string) => dispatch(a.resetPassword(email)),
            confirmAccount: (token: string) => dispatch(a.confirmAccount(token)),
            signUp: (requestDto: SignUpRequestDto) => dispatch(a.signUp(requestDto)),
            resendConfirmation: (email: string) => dispatch(a.resendConfirmation(email)),
            signIn: (credentials: SignInCredentialsInterface) => dispatch(a.signIn(credentials)),
            changePassword: (token: string, password: string) => dispatch(a.changePassword(token, password)),
        }),
    )(BaseComponent)
}
