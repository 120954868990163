import styled from "styled-components"
import {compose} from "redux"

// Components
import DetailsModal from "./SettingsModal"

// HOCs
import withSettingsToggle, {WithSettingsToggleProps} from "../../modules/common/hoc/withSettingsToggle"
import withAuth, {WithAuthProps} from "../../modules/authorization/hoc/withAuth"

export type Props = {
    className?: string
} & WithSettingsToggleProps & WithAuthProps

const StyledModal = styled(DetailsModal)`
    top: 120px;
    padding-bottom: 0;
    
    .ant-modal-body {
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        padding: 10px 15px 30px 15px;
    }
    
    .user-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    
    .sign-out-button {
        color: ${({theme}) => theme.color.blue};
        position: absolute;
        top: 0;
        right: 0;
    }
`

export default compose(
    withAuth,
    withSettingsToggle,
)(StyledModal)