import {combineReducers} from "redux";

// Constants
import {StoreKeys} from "../constants";

// Reducers
import authorizationReducer from "../../modules/authorization/store/reducer"
import patientsReducer from "../../modules/patients/store/reducer"
import patientsFactoryReducer from "../../modules/patients-factory/store/reducer"
import groupsReducer from "../../modules/groups/store/reducer"
import recordsReducer from "../../modules/records/store/reducer"
import commonReducer from "../../modules/common/store/reducer"
import singlePatientReducer from "../../modules/single-patient/store/reducer"
import singleRecordReducer from "../../modules/single-record/store/reducer"

export default combineReducers({
    [StoreKeys.AUTHORIZATION]: authorizationReducer,
    [StoreKeys.PATIENTS]: patientsReducer,
    [StoreKeys.PATIENTS_FACTORY]: patientsFactoryReducer,
    [StoreKeys.SINGLE_PATIENT]: singlePatientReducer,
    [StoreKeys.SINGLE_RECORD]: singleRecordReducer,
    [StoreKeys.GROUPS]: groupsReducer,
    [StoreKeys.RECORDS]: recordsReducer,
    [StoreKeys.COMMON]: commonReducer,
});
