import React from "react"
import {Layout} from "antd"
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

// Components
import Copyright from "../Copyright";

interface FooterProps {
    className?: string
}

export default function Footer(props: FooterProps) {
    const {className = null} = props;
    const { publicRuntimeConfig: {THEME} } = getConfig();
    const isUpolife = THEME === "upolife";
    const router = useRouter();
    const { t } = useTranslation();

    function renderLocaleSwitcher() {
        return router.locales.map(l => (
            <div key={l} style={{ padding: '0px 5px' }}>
                <Link
                    href={router.pathname}
                    as={router.asPath}
                    locale={l}
                >
                    {l}
                </Link>
            </div>
        ))
    }

    return (
        <Layout.Footer className={className}>
            <div className="footer-inner">
                <Copyright/>
                <div style={{ display: 'flex' }}>
                    {renderLocaleSwitcher()}
                </div>
                <div className="links">
                    <a
                        className="link"
                        href="https://docs.google.com/document/d/e/2PACX-1vSHNekBJfIlzgUfLU1M8ERlcftPVnp6CGHBQmXRrxn5973vfrMs2Zo1xMG3Io5jFg/pub"
                        target="_blank"
                    >
                        {t("common:footer.terms_of_service")}
                    </a>
                    <a
                        className="link"
                        href="https://docs.google.com/document/d/e/2PACX-1vQd6caVSupIEEgW5QKn08WDHQB7xz_9STcfKmGQXe-3sltBVYE7GyOZksvuETrzsQ/pub"
                        target="_blank"
                    >
                        {t("common:footer.privacy_policy")}
                    </a>
                    {
                        !isUpolife &&
                        <>
                            <a
                                className="link"
                                href="https://docs.google.com/document/d/e/2PACX-1vSgQOPRjD3FAbBMfS1jbCvpYRR_RXBJFlvwREtT-4cpbzISao5iBOfLJ0-P47rfITN49GMIZeXoXjyw/pub"
                                target="_blank"
                            >
                                {t("common:footer.cookies_policy")}
                            </a>
                            <a
                                className="link"
                                href="https://docs.google.com/document/d/e/2PACX-1vRkZys2Fn6_aU7l2aJAHO4Qo_W8lRrl-KUoLqbSea-5ZfNSRqPn70-77sGZZIgF3w/pub"
                                target="_blank"
                            >
                                {t("common:footer.accessibility_statement")}
                            </a>
                            <a
                                className="link"
                                href="https://docs.google.com/document/d/e/2PACX-1vS817GWrt2ZzVWzk9AF3cNBv8ygWu2fqwIYxP_nM9_KLcSYNZIQ8Jt1cw-F1VdQdBSK1kd1oGvjYd1W/pub"
                                target="_blank"
                            >
                                {t("common:footer.instruction_of_Use")}
                            </a>
                        </>
                    }
                </div>
            </div>
        </Layout.Footer>
    );
}
