import {Dispatch} from "redux"
import {connect} from "react-redux"
import {NextComponentType} from "next"

// Actions
import * as a from "../store/actions"

// Models
import {AppState} from "../../../store/models"

// Selectors
import {changePasswordStateSelector, changeUserPasswordStateSelector} from "../store/selectors"

export type WithChangePasswordProps = {
    passwordChangeSent: boolean
    passwordChangeSending: boolean
    changePassword(token: string, password: string): void
    userPasswordChanged: boolean
    userPasswordChanging: boolean
    changeUserPassword(password: string): void
}

export default function withChangePassword(BaseComponent: NextComponentType) {
    return connect(
        (state: AppState) => ({...changePasswordStateSelector(state), ...changeUserPasswordStateSelector(state)}),
        (dispatch: Dispatch) => ({
            changePassword: (token: string, password: string) => dispatch(a.changePassword(token, password)),
            changeUserPassword: (password: string) => dispatch(a.changeUserPassword.request(password)),
        }),
    )(BaseComponent)
}
