import {createReducer, getType} from "typesafe-actions"

// Models
import {CommonStateType} from "../../models"

// Actions
import * as a from "../actions"

const InitialCommonState: CommonStateType = {
    countryCodes: {},
    sidebarClosed: false,
    settingsModalVisible: false,
    countryCodesLoading: false
};

const commonReducer = createReducer(InitialCommonState, {
    [getType(a.loadCountryCodes.request)]: state => ({
        ...state,
        countryCodesLoading: true,
    }),
    [getType(a.loadCountryCodes.success)]: (state, {payload}) => ({
        ...state,
        countryCodes: payload,
        countryCodesLoading: false,
    }),
    [getType(a.loadCountryCodes.failure)]: state => ({
        ...state,
        countryCodesLoading: false,
    }),
    [getType(a.toggleSidebar)]: state => ({
        ...state,
        sidebarClosed: !state.sidebarClosed,
    }),
    [getType(a.toggleSettingsModal)]: state => ({
        ...state,
        settingsModalVisible: !state.settingsModalVisible,
    }),
});

export default commonReducer
