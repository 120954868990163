import {compose} from "redux"
import styled from "styled-components"
import {Router, withRouter} from "next/router"

// Component
import CoreLayout from "./CoreLayout"

// HOCs
import withUser, {WithUserProps} from "../../../hoc/withUser"

export type LayoutProps = {
    className: string
    children: Array<JSX.Element> | JSX.Element
    router: Router
} & WithUserProps

const StyledLayout = styled(CoreLayout)`
  height: 100vh;

  .ant-layout {
    overflow: hidden;
  }

  .ant-layout-content {
      max-height: ${({user}) => user === null ? "100vh" : "calc(100vh - 70px)"};
      
      @media print {
        max-height: initial;
      }
  }
  
  @media print {
    height: auto;
  }
`;

export default compose(
    withRouter,
    withUser,
)(StyledLayout);