import {createReducer, getType} from "typesafe-actions"

// Actions
import * as a from "../actions"
import { signOutSuccess } from "../../../authorization/store/actions"
import { fetchByIdAsync } from "../../../single-patient/store/actions"
import { editPatientAsync } from "../../../patients-factory/store/actions"

// Constants
import {FILTER_DEFAULTS, PAGINATION_DEFAULTS, SEARCH_DEFAULTS} from "../../constants"

// Models
import {PatientsStateInterface} from "../../models"

export const InitialPatientsState: PatientsStateInterface = {
    patientsById: {},
    patientsIds: [],
    patientsFetching: false,
    morePatientsFetching: false,
    recentPatientsIds: [],
    recentFetching: false,
    querying: false,
    previousPage: 0,
    ...SEARCH_DEFAULTS,
    ...FILTER_DEFAULTS,
    ...PAGINATION_DEFAULTS,
    doctorStatsFetching: false,
    doctorStats: undefined, // TODO: extract to different store
    inviteCode: "",
    inviteCodeGenerating: false,
    inviteModalVisible: false,
};

const patientsReducer = createReducer(InitialPatientsState, {
    [getType(a.fetchPatientsAsync.request)]: state => ({
        ...state,
        patientsFetching: true,
    }),
    [getType(a.fetchPatientsAsync.success)]: (state, {payload}) => ({
        ...state,
        patientsById: {
            ...state.patientsById,
            ...payload.patientsById,
        },
        patientsIds: payload.patientsIds,
        patientsFetching: false,
        querying: false,
    }),
    [getType(a.fetchPatientsAsync.failure)]: state => ({
        ...state,
        querying: false,
        patientsFetching: false,
        morePatientsFetching: false,
    }),
    [getType(a.mergePatients)]: (state, {payload}) => ({
        ...state,
        patientsById: {
            ...state.patientsById,
            ...payload.patientsById,
        },
        patientsIds: [
            ...state.patientsIds,
            ...payload.patientsIds,
        ]
    }),
    [getType(a.safeMergePatients)]: (state, {payload}) => ({
        ...state,
        patientsById: {
            ...payload.patientsById,
            ...state.patientsById,
        },
    }),
    [getType(a.fetchPatientsNextPageAsync.request)]: state => ({
        ...state,
        morePatientsFetching: true,
        previousPage: state.currentPage,
        currentPage: state.currentPage + 1,
    }),
    [getType(a.fetchPatientsNextPageAsync.failure)]: state => ({
        ...state,
        morePatientsFetching: false,
    }),
    [getType(a.fetchPatientsNextPageAsync.success)]: (state, {payload}) => ({
        ...state,
        patientsById: {
            ...state.patientsById,
            ...payload.patientsById,
        },
        patientsIds: [
            ...state.patientsIds,
            ...payload.patientsIds,
        ],
        morePatientsFetching: false,
    }),
    [getType(a.setTotalPages)]: (state, {payload}) => ({
        ...state,
        totalPages: payload.totalPages,
    }),
    [getType(a.setTotalElements)]: (state, {payload}) => ({
        ...state,
        totalElements: payload.totalElements,
    }),
    [getType(a.fetchRecentPatientsAsync.request)]: state => ({
        ...state,
        recentFetching: true,
    }),
    [getType(a.fetchRecentPatientsAsync.failure)]: state => ({
        ...state,
        recentFetching: false,
    }),
    [getType(a.fetchRecentPatientsAsync.success)]: (state, {payload}) => ({
        ...state,
        patientsById: {
            ...state.patientsById,
            ...payload.patientsById,
        },
        recentPatientsIds: payload.patientsIds,
        recentFetching: false,
    }),
    [getType(a.setSearchQuery)]: (state, {payload}) => ({
        ...state,
        query: payload.query,
        previousPage: 0,
        currentPage: 0,
    }),
    [getType(a.setPatientsBeingQueried)]: state => ({
        ...state,
        querying: true,
    }),
    [getType(a.setCurrentPage)]: (state, {payload}) => ({
        ...state,
        currentPage: payload.page,
    }),
    [getType(a.fetchDoctorStatsAsync.request)]: state => ({
        ...state,
        doctorStatsFetching: true,
    }),
    [getType(a.fetchDoctorStatsAsync.success)]: (state, {payload}) => ({
        ...state,
        doctorStats: payload,
        doctorStatsFetching: false,
    }),
    [getType(a.fetchDoctorStatsAsync.failure)]: state => ({
        ...state,
        doctorStatsFetching: false,
    }),
    [getType(fetchByIdAsync.success)]: (state, {payload}) => ({
        ...state,
        patientsById: {
            ...state.patientsById,
            [payload.id]: payload,
        },
    }),
    [getType(editPatientAsync.success)]: (state, {payload}) => ({
        ...state,
        patientsById: {
            ...state.patientsById,
            [payload.id]: payload,
        },
    }),
    [getType(a.generateInviteCodeAsync.request)]: state => ({
        ...state,
        inviteCodeGenerating: true,
    }),
    [getType(a.generateInviteCodeAsync.success)]: (state, {payload}) => ({
        ...state,
        inviteCode: payload,
        inviteCodeGenerating: false,
    }),
    [getType(a.generateInviteCodeAsync.failure)]: state => ({
        ...state,
        inviteCodeGenerating: false,
    }),
    [getType(a.setInviteModalVisible)]: (state, {payload}) => ({
        ...state,
        inviteModalVisible: payload.visible,
    }),
    [getType(signOutSuccess)]: () => InitialPatientsState,
});

export default patientsReducer
