import React from "react"
import {Button, Modal} from "antd"
import useTranslation from 'next-translate/useTranslation'

// Models
import {Props} from "./index"

// Components
import EditComponent from "./EditComponent"
import ChangePasswordComponent from "./ChangePasswordComponent"

const SettingsModal = (props: Props) => {
    const {
        className,
        isVisible,
        toggleSettingsModal,
        signOut,
    } = props
    const { t } = useTranslation();

    function handleSignOut() {
        toggleSettingsModal()
        signOut()
    }

    return (
        <Modal
            width={400}
            footer={null}
            title={t('common:settings')}
            destroyOnClose
            className={className}
            visible={isVisible}
            onCancel={toggleSettingsModal}
        >
            <div className="user-container">
                <EditComponent/>
                <Button className="sign-out-button" onClick={handleSignOut} type="link">
                    {t('common:sign_out')}
                </Button>
            </div>
            <ChangePasswordComponent/>
        </Modal>
    )
}

export default SettingsModal