import styled from "styled-components"
import Footer from "./Footer";
import {StyledProps} from "../../models";

export default styled(Footer)`
  display: flex;
  justify-content: center;
  .links {
    display: flex;
  }

  .link {
    color: ${(props: StyledProps) => props.theme.color.white};
    padding-right: 10px;

    &:last-child {
      padding-right: 0px;
    }
  }

  &.ant-layout-footer {
    background-color: ${(props: StyledProps) => props.theme.color.alternative};
    color: ${(props: StyledProps) => props.theme.color.white};
  }
  
  & .footer-inner {
    display: flex;
    justify-content: space-around;
    max-width: 1440px;
    max-height:10px;
    width: 100%;
    height:10px;
    padding-top:0px
  
  }
`
