import React from "react"

// Constants
import {Role} from "../constants"

// HOCs
import withUser from "./withUser"

export default function withAuthorities(roles: Array<Role>) {
    return function (BaseComponent: React.ComponentType<any>): React.ComponentType<any> {

        function withAuthorities(props): JSX.Element {
            const {user} = props

            if (user === null || roles.includes(user.role) === false) {
                return null
            }

            return <BaseComponent {...props}/>
        }

        return withUser(withAuthorities)
    }
}
