import {createReducer, getType} from "typesafe-actions"

// Models
import {SingleRecordState} from "../../models"

// Constants
import {DefaultLead} from "../../constants"

// Actions
import * as a from "../actions"
import { setActiveRecord } from "../../../single-patient/store/actions"

export const InitialSingleRecordState: SingleRecordState = {
    selectedLead: DefaultLead,
    summaryFetching: true,
    markingAsReviewed: false,
    rawSignalsFetching: false,
    rawFileFetching: false,
    rawFile: null,
    histogramChartDataFetching: false,
    detailsFetching: false,
    isDetailsVisible: false,
    excelFile: null,
    pdfFile:null,
    excelFileFetching: false,
    pdfFileFetching: false
};

const singleRecordReducer = createReducer(InitialSingleRecordState, {
    [getType(a.fetchRawSignalByIdAsync.request)]: state => ({
        ...state,
        rawSignalsFetching: true,
    }),
    [getType(a.fetchRawSignalByIdAsync.success)]: state => ({
        ...state,
        rawSignalsFetching: false,
    }),
    [getType(a.fetchRawSignalByIdAsync.failure)]: state => ({
        ...state,
        rawSignalsFetching: false,
    }),
    [getType(a.fetchRawFileAsync.request)]: state => ({
        ...state,
        rawFileFetching: true,
    }),
    [getType(a.fetchRawFileAsync.success)]: state => ({
        ...state,
        rawFileFetching: false,
        rawFile: null,
    }),
    [getType(a.fetchRawFileAsync.failure)]: state => ({
        ...state,
        rawFileFetching: false,
        rawFile: null,
    }),
    [getType(a.setRawFile)]: (state, {payload}) => ({
        ...state,
        rawFile: payload,
    }),
    [getType(a.fetchExcelFileAsync.request)]: state => ({
        ...state,
        excelFileFetching: true,
    }),
    [getType(a.fetchExcelFileAsync.success)]: state => ({
        ...state,
        excelFileFetching: false,
        excelFile: null,
    }),
    [getType(a.fetchExcelFileAsync.failure)]: state => ({
        ...state,
        excelFileFetching: false,
        excelFile: null,
    }),
    [getType(a.setExcelFile)]: (state, {payload}) => ({
        ...state,
        excelFile: payload,
    }),
    [getType(a.fetchPdfFileAsync.request)]: state => ({
        ...state,
        pdfFileFetching: true,
    }),
    [getType(a.fetchPdfFileAsync.success)]: state => ({
        ...state,
        pdfFileFetching: false,
        pdfFile: null,
    }),
    [getType(a.fetchPdfFileAsync.failure)]: state => ({
        ...state,
        pdfFileFetching: false,
        pdfFile: null,
    }),
    [getType(a.setPdfFile)]: (state, {payload}) => ({
        ...state,
        pdfFile: payload,
    }),
    [getType(a.fetchSummaryByIdAsync.request)]: state => ({
        ...state,
        summaryFetching: true,
    }),
    [getType(a.fetchSummaryByIdAsync.success)]: state => ({
        ...state,
        summaryFetching: false,
    }),
    [getType(a.fetchSummaryByIdAsync.failure)]: state => ({
        ...state,
        summaryFetching: false,
    }),
    [getType(a.markAsReviewed.request)]: state => ({
        ...state,
        markingAsReviewed: true,
    }),
    [getType(a.markAsReviewed.success)]: state => ({
        ...state,
        markingAsReviewed: false,
    }),
    [getType(a.markAsReviewed.failure)]: state => ({
        ...state,
        markingAsReviewed: false,
    }),
    [getType(a.fetchHistogramChartDataAsync.request)]: state => ({
        ...state,
        histogramChartDataFetching: true,
    }),
    [getType(a.fetchHistogramChartDataAsync.success)]: state => ({
        ...state,
        histogramChartDataFetching: false,
    }),
    [getType(a.fetchHistogramChartDataAsync.failure)]: state => ({
        ...state,
        histogramChartDataFetching: false,
    }),
    [getType(a.selectLead)]: (state, {payload: {lead}}) => ({
        ...state,
        selectedLead: lead,
    }),
    [getType(a.fetchDetailsAsync.request)]: state => ({
        ...state,
        detailsFetching: true,
    }),
    [getType(a.fetchDetailsAsync.success)]: state => ({
        ...state,
        detailsFetching: false,
    }),
    [getType(a.fetchDetailsAsync.failure)]: state => ({
        ...state,
        detailsFetching: false,
    }),
    [getType(a.setAvailableLeads)]: (state, {payload: {leads}}) => ({
        ...state,
        leads,
    }),
    [getType(a.toggleDetailsModal)]: state => ({
        ...state,
        isDetailsVisible: !state.isDetailsVisible,
    }),

    // From Single patient
    [getType(setActiveRecord)]: state => ({
        ...state,
        selectedLead: InitialSingleRecordState.selectedLead,
    }),
});

export default singleRecordReducer
