import React from "react"
import {Dispatch} from "redux"
import {connect} from "react-redux"

// Actions
import * as a from "../store/actions"

// Models
import {AppState} from "../../../store/models"

// Selectors
import {selectSettingsModalVisible} from "../store/selectors"

export type WithSettingsToggleProps = {
    isVisible: boolean
    toggleSettingsModal(): void
}

export default function withSettingsToggle(BaseComponent: React.ComponentType<any>) {
    return connect(
        (state: AppState) => ({
            isVisible: selectSettingsModalVisible(state),
        }),
        (dispatch: Dispatch) => ({
            toggleSettingsModal: () => dispatch(a.toggleSettingsModal()),
        }),
    )(BaseComponent)
}
