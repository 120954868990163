import {createReducer, getType} from "typesafe-actions"

// Models
import {SinglePatientStateType} from "../../models"

// Actions
import * as a from "../actions"
import { signOutSuccess } from "../../../authorization/store/actions"

// Constants
import {INITIAL_PATIENT_RECORDS_PAGE} from "../../constants"

const InitialSinglePatientState: SinglePatientStateType = {
    id: null,
    fetching: false,
    patientError: null,
    recordsIds: [],
    recordsFetching: true,
    moreRecordsFetching: false,
    recordsPage: INITIAL_PATIENT_RECORDS_PAGE,
    recordsTotal: 0,
    recordsTotalPages: 1,
    activeRecordId: null,
};

const singlePatientReducer = createReducer(InitialSinglePatientState, {
    [getType(a.setCurrentPatientId)]: (state, {payload}) => ({
        ...state,
        id: payload.id,
    }),
    [getType(a.fetchByIdAsync.request)]: state => ({
        ...state,
        fetching: true,
    }),
    [getType(a.fetchByIdAsync.success)]: state => ({
        ...state,
        fetching: false,
    }),
    [getType(a.fetchByIdAsync.failure)]: (state, {payload: patientError}) => ({
        ...state,
        fetching: false,
        patientError,
    }),
    [getType(a.fetchRecordsAsync.request)]: state => ({
        ...state,
        recordsFetching: true,
    }),
    [getType(a.fetchRecordsAsync.success)]: (state, {payload: recordsIds}) => ({
        ...state,
        recordsFetching: false,
        recordsIds,
    }),
    [getType(a.fetchRecordsAsync.failure)]: (state, {payload}) => ({
        ...state,
        recordsFetching: false,
        patientError: payload,
    }),
    [getType(a.setRecordsTotal)]: (state, {payload}) => ({
        ...state,
        recordsTotal: payload.totalElements,
        recordsTotalPages: payload.totalPages,
    }),
    [getType(a.setRecordsPage)]: (state, {payload}) => ({
        ...state,
        recordsPage: payload.page,
    }),
    [getType(a.fetchRecordsNextPageAsync.request)]: state => ({
        ...state,
        moreRecordsFetching: true,
        recordsPage: state.recordsPage + 1,
    }),
    [getType(a.fetchRecordsNextPageAsync.success)]: (state, {payload: recordsIds}) => ({
        ...state,
        moreRecordsFetching: false,
        recordsIds: [
            ...state.recordsIds,
            ...recordsIds,
        ],
    }),
    [getType(a.fetchRecordsNextPageAsync.failure)]: state => ({
        ...state,
        moreRecordsFetching: false,
    }),
    [getType(a.setActiveRecord)]: (state, {payload}) => ({
        ...state,
        activeRecordId: payload.id,
    }),
    [getType(signOutSuccess)]: () => InitialSinglePatientState,
});

export default singlePatientReducer
