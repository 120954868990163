import React from "react"
import getConfig from "next/config"

interface Props {
    className?: string
}

const Copyright: React.FC<Props> = props =>  {
    const {className = null} = props;
    const {
        publicRuntimeConfig: {THEME},
    } = getConfig();
    const isUpolife = THEME === "upolife"

    return (
        <div className={className}>
            <span>&copy; {isUpolife ? "Upolife" : "Cardiolyse"}, {new Date().getFullYear()}. All rights reserved.</span>
        </div>
    );
}

export default Copyright