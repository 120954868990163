import React from "react"
import styled from "styled-components"
import {compose} from "redux"

// HOCs
import withAuthorities from "../../hoc/withAuthorities"

// Constants
import {Role} from "../../constants"

// Models
import {WithoutClassNameProps} from "../../models"

type Props = {
    className?: string
}

const LiveDemoLabel = (props: Props) => {
    const {className} = props

    return (
        <div className={className}>
            <span>
                Live Demo
            </span>
        </div>
    )
}

const StyledLabel = styled(LiveDemoLabel)`
    text-align: center;
    padding: 5px 25px;
    border-radius: 25px;
    background-color: aliceblue;
    color: ${({theme}) => theme.color.blue};
    font-size: 16px;
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    text-transform: uppercase;
    
    
    @media screen and (max-width: ${({theme}) => theme.breakpoints.lg}) {
        padding: 5px 10px;
        font-size: 14px;
    }
` as WithoutClassNameProps<Props>

export default compose(
    withAuthorities([Role.DEMO_DOCTOR])
)(StyledLabel)