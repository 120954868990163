import React from "react"
import styled from "styled-components"
import {Layout as AntLayout} from "antd"
import Footer from '../../Footer'
// Components
import AuthSidebar from "../../UnauthorizedSidebar"

function UnauthorizedLayout(props) {
    return (
        <React.Fragment>
            {
              !props.noSider &&
              <AuthSidebar/>
            }
            <AntLayout.Content className={props.className}>
                {props.children}
                <Footer/>
            </AntLayout.Content>

        </React.Fragment>
    )
}

export default styled(UnauthorizedLayout)`
  background: url("../../../../static/assets/background/bg.png") top left/cover no-repeat;
  
  @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url("../../../../static/assets/background/bg@2x.png");
  }
`