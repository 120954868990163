import styled from "styled-components"

// Component
import Menu from "./Menu";

export type MenuProps = {
    className?: string
}

const StyledMenu = styled(Menu)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    width: 376px;
    border-bottom: 1px solid ${({theme}) => theme.color.border};
    
    & .menu-item {
        border-right: 1px solid ${({theme}) => theme.color.border};
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
    }
    
    & .menu-img {
        width: 22px;
        height: 22px;
        object-fit: contain;
    }
    
    & .menu-link {
        color: ${({theme}) => theme.color.darkGray};
        font-size: 12px;
        opacity: 0.5;
        margin-top: 5px;
        transition: all 0.15s ease-in-out;
        line-height: 1;
    }
    
    & .menu-link__active {
        opacity: 1;
        color: ${({theme}) => theme.color.blue};
    }
`

export default StyledMenu;