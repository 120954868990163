import styled from "styled-components"
import {compose} from "redux"

// Component
import AuthorizedHeader from "./AuthorizedHeader"

// Models
import {StyledProps} from "../../models"

// HOCs
import withSettingsToggle, {WithSettingsToggleProps} from "../../modules/common/hoc/withSettingsToggle"
import withAuth, {WithAuthProps} from "../../modules/authorization/hoc/withAuth"
import withUser, {WithUserProps} from "../../hoc/withUser"

const StyledAuthorizedHeader = styled(AuthorizedHeader)`
    align-items: center;
    background-color: ${(props: StyledProps) => props.theme.color.white};
    border-bottom: 1px solid ${(props: StyledProps) => props.theme.color.border};
    box-shadow: 0 3px 20px -2px rgba(117, 140, 187, 0.19);
    display: flex;
    height: ${(props: StyledProps) => props.theme.constants.headerHeight}px;
    justify-content: space-between;
    
    .container {
      align-items: center;
      box-shadow: 3px 8px 10px -5px rgba(117, 140, 187, 0.19);
      display: flex;
      flex-grow: 1;
      height: ${(props: StyledProps) => props.theme.constants.headerHeight}px;
      justify-content: space-between;
      padding-left: 20px;
      position: relative;
    }
    
    .right-menu-container {
        display: flex;
        align-items: center;
        height: 100%;
    }
    
    .right-menu-item {
        width: 100px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        border-left: 1px solid ${({theme}) => theme.color.border};
    }
    
    .right-menu-label {
        font-size: 12px;
        opacity: 0.5;
        line-height: 1;
        margin-top: 5px;
        color: ${({theme}) => theme.color.main};
    }
    
    @media screen and (max-width: ${({theme}) => theme.breakpoints.lg}) {
        .logo-container {
            max-width: 150px;
        }
    }
    
    @media print {
      display: none;
    }
` as any;

export type AuthorizedHeaderProps = {
    className: string
    isEcgViewer: boolean
} & WithSettingsToggleProps & WithUserProps & WithAuthProps

export default compose(
    withSettingsToggle,
    withUser,
    withAuth,
)(StyledAuthorizedHeader)
