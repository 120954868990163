import React from "react"

// Components
import AuthorizedHeader from "../../AuthorizedHeader"
import Footer from "../../Footer"

/**
 * @param props
 * @returns {JSX.Element}
 */
export default function AuthorizedLayout(props) {
    return (
        <React.Fragment>
            <AuthorizedHeader isEcgViewer={props.isEcgViewer} />
            {props.children}
            <Footer />
        </React.Fragment>
    )
}
