import React from "react"
import {Dispatch} from "redux"
import {connect} from "react-redux"

// Actions
import * as a from "../store/actions"

// Models
import {AppState} from "../../../store/models"
import {UserEditDto} from "../models"

// Selectors
import {userEditingSelector} from "../store/selectors"

export type WithUserEditProps = {
    userEditing: boolean
    editUser(user: UserEditDto): void
}

export default function withUserEdit(BaseComponent: React.ComponentType<any>) {
    return connect(
        (state: AppState) => ({
            userEditing: userEditingSelector(state),
        }),
        (dispatch: Dispatch) => ({
            editUser: (user: UserEditDto) => dispatch(a.editUser.request(user)),
        }),
    )(BaseComponent)
}
