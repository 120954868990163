import {createReducer, getType} from "typesafe-actions"

// Types
import * as a from "../actions";

// Models
import {AuthStateInterface} from "../../models";

export const InitialAuthorizationState: AuthStateInterface = {
    user: null,
    signingIn: false,
    authorizing: true,
    serverError: null,
    isDemo: false,
    // Email confirmation
    emailConfirming: false,
    emailConfirmed: false,
    emailConfirmError: null,
    confirmResending: false,
    confirmResent: false,
    confirmResendError: null,
    // Password
    passwordResetSending: false,
    passwordResetSent: false,
    passwordResetSendError: null,
    passwordChangeSending: false,
    passwordChangeSent: false,
    passwordChangeSendError: null,
    // Sign up
    signingUp: false,
    signedUp: false,
    // Edit user
    userEditing: false,
    userPasswordChanging: false,
    userPasswordChanged: false,
};

const authorizationReducer = createReducer(InitialAuthorizationState, {
    [getType(a.signIn)]: state => ({
        ...state,
        signingIn: true,
    }),
    [getType(a.signInSuccess)]: (state, {payload}) => ({
        ...state,
        user: payload.user,
        signingIn: false,
    }),
    [getType(a.signInFailure)]: (state, {payload}) => ({
        ...state,
        signingIn: false,
        serverError: payload.error,
        isDemo: false,
    }),
    [getType(a.demoSignIn)]: state => ({
        ...state,
        isDemo: true,
        signingIn: true,
    }),
    [getType(a.fetchCurrentUserSuccess)]: (state, {payload}) => ({
        ...state,
        user: payload.user,
    }),
    [getType(a.signOutSuccess)]: () => InitialAuthorizationState,
    [getType(a.confirmAccount)]: state => ({
        ...state,
        emailConfirming: true,
        emailConfirmed: false,
        emailConfirmError: null,
    }),
    [getType(a.confirmAccountSuccess)]: state => ({
        ...state,
        emailConfirming: false,
        emailConfirmed: true,
    }),
    [getType(a.confirmAccountFailure)]: (state, {payload}) => ({
        ...state,
        emailConfirming: false,
        emailConfirmError: payload.error,
    }),
    [getType(a.resendConfirmation)]: state => ({
        ...state,
        confirmResending: true,
        confirmResent: false,
        confirmResendError: null,
    }),
    [getType(a.resendConfirmationSuccess)]: state => ({
        ...state,
        confirmResending: false,
        confirmResent: true,
    }),
    [getType(a.resendConfirmationFailure)]: (state, {payload}) => ({
        ...state,
        confirmResending: false,
        confirmResendError: payload.error,
    }),
    [getType(a.resetPassword)]: state => ({
        ...state,
        passwordResetSending: true,
        passwordResetSent: false,
        passwordResetSendError: null,
    }),
    [getType(a.resetPasswordSuccess)]: state => ({
        ...state,
        passwordResetSending: false,
        passwordResetSent: true,
    }),
    [getType(a.resetPasswordFailure)]: (state, {payload}) => ({
        ...state,
        passwordResetSending: false,
        serverError: payload.error,
    }),
    [getType(a.changePassword)]: state => ({
        ...state,
        passwordChangeSending: true,
        passwordChangeSent: false,
        passwordChangeSendError: null,
    }),
    [getType(a.changePasswordSuccess)]: state => ({
        ...state,
        passwordChangeSending: false,
        passwordChangeSent: true,
    }),
    [getType(a.changePasswordFailure)]: (state, {payload}) => ({
        ...state,
        passwordChangeSending: false,
        serverError: payload.error,
    }),
    [getType(a.signUp)]: state => ({
        ...state,
        signingUp: true,
    }),
    [getType(a.signUpSuccess)]: state => ({
        ...state,
        signingUp: false,
        signedUp: true,
    }),
    [getType(a.signUpFailure)]: (state, {payload}) => ({
        ...state,
        signingUp: false,
        signedUp: false,
        serverError: payload.error,
    }),
    [getType(a.clearServerError)]: state => ({
        ...state,
        serverError: null,
    }),
    [getType(a.editUser.request)]: state => ({
        ...state,
        userEditing: true,
    }),
    [getType(a.editUser.success)]: (state, {payload}) => ({
        ...state,
        userEditing: false,
        user: payload.data,
    }),
    [getType(a.editUser.failure)]: state => ({
        ...state,
        userEditing: false,
    }),
    [getType(a.changeUserPassword.request)]: state => ({
        ...state,
        userPasswordChanging: true,
    }),
    [getType(a.changeUserPassword.success)]: state => ({
        ...state,
        userPasswordChanging: false,
        userPasswordChanged: true,
    }),
    [getType(a.changeUserPassword.failure)]: state => ({
        ...state,
        userPasswordChanging: false,
        userPasswordChanged: false,
    }),
    [getType(a.signOutSuccess)]: () => InitialAuthorizationState,
});

export default authorizationReducer
