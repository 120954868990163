import React from "react"
import getConfig from "next/config"
import useTranslation from 'next-translate/useTranslation'

// Components
import Sidebar from "../Sidebar/Sidebar"
import LiveDemoButton from "../LiveDemoButton"
import Logo from "../Logo"

// Models
import {AuthSidebarProps} from "./index"

const UnauthorizedSidebar:React.FC<AuthSidebarProps> = props =>  {
    const {className = null} = props;
    const { publicRuntimeConfig: {THEME} } = getConfig();
    const isUpolife = THEME === "upolife"
    const { t } = useTranslation();

    return (
        <Sidebar className={className}>
            <Logo white={isUpolife}/>
            <div className="content">
                <h2 className="title">
                    {t('common:unauthorized_sidebar.title')}
                </h2>
                <p className="desc">
                    {t('common:unauthorized_sidebar.desc')}
                </p>
                <LiveDemoButton/>
            </div>

        </Sidebar>
    )
}

export default UnauthorizedSidebar