import {createLogger} from "redux-logger"
import {createStore, applyMiddleware, Action} from "redux"
import {createEpicMiddleware, EpicMiddleware} from "redux-observable"

// Models
import {AppState} from "./models"

// Root reducer
import rootReducer from "./root-reducer"

// Root epic
import rootEpic from "./root-epic"

// Dependencies
import EpicDependencies, {EpicDependenciesType} from "./dependencies"

const logger = createLogger({
    collapsed: true,
});

let developmentMiddleware = [];

if (process.env.NODE_ENV === "development") {
    developmentMiddleware.push(logger);
}

const epicMiddleware: EpicMiddleware<Action<any>, Action<any>, AppState, EpicDependenciesType> = createEpicMiddleware({
    dependencies: EpicDependencies,
});

const middleware = applyMiddleware(
    epicMiddleware,
    ...developmentMiddleware,
);

const initialState = {};

export default function(state = initialState) {
    const store = createStore(rootReducer, state, middleware);

    epicMiddleware.run(rootEpic);

    return store
};
