import React from "react"
import classNames from "classnames"
import {useRouter} from "next/router"
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

// Helpers
import {menuItems} from "../../helpers"

// Models
import {MenuProps} from "./index"

const Menu = (props: MenuProps) => {
    const {className = null} = props;
    const {asPath} = useRouter();
    const { t } = useTranslation();

    return (
        <nav className={className}>
            {menuItems.map(item => {
                const isActive = asPath.includes(item.href);

                return (
                    <Link href={item.href} key={item.href}>
                        <a
                            href={item.href}
                            target={item.target}
                            className="menu-item"
                        >
                            <img
                                alt={item.text}
                                className="menu-img"
                                src={isActive ? item.activeIcon + ".svg" : item.icon + ".svg"}
                            />
                            <span className={classNames("menu-link", isActive && "menu-link__active")}>
                            {t(`common:menu.${item.text}`)}
                        </span>
                        </a>
                    </Link>
                )
            })}
        </nav>
    );
}

export default Menu;