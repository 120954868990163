import React from "react"
import Router from "next/router"
import {Layout as AntLayout, message} from "antd"

// Components
import EmptyLayout from "../EmptyLayout"
import UnauthorizedLayout from "../UnauthorizedLayout"
import AuthorizedLayout from "../AuthorizedLayout/AuthorizedLayout"

// Models
import {LayoutProps} from "./index"
import {Routes} from "../../../constants"

message.config({
    maxCount: 1,
});

let dismissCallback = null;

/**
 * @param props
 * @returns {JSX.Element}
 */
export default function CoreLayout(props: LayoutProps) {
    const {className, user, children, router: {asPath}} = props;

    React.useEffect(() => {
        Router.events.on("routeChangeStart", handleRouteChangeStart);
        Router.events.on("routeChangeComplete", handleRouteChangeComplete);
        Router.events.on("routeChangeError", handleRouteChangeComplete);
    }, []);

    function handleRouteChangeStart() {
        dismissCallback = message.loading("Loading...", 0);
    }

    function handleRouteChangeComplete() {
        if (dismissCallback !== null) {
            dismissCallback();
        }
    }

    function renderContent() {
        if (asPath.includes(Routes.MOBILE)) {
            return (
                <EmptyLayout>
                    {children}
                </EmptyLayout>
            )
        }

        if (user === null && !asPath.includes(Routes.ECGVIEWER)) {
            return (
                <UnauthorizedLayout>
                    {children}
                </UnauthorizedLayout>
            )
        }

        return (
            <AuthorizedLayout isEcgViewer={asPath.includes(Routes.ECGVIEWER)}>
                {children}
            </AuthorizedLayout>
        )
    }

    return (
        <AntLayout className={className} hasSider={user === null && !asPath.includes(Routes.ECGVIEWER)}>
            {renderContent()}
        </AntLayout>
    )
}