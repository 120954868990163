import {createReducer, getType} from "typesafe-actions"

// Models
import {GroupsStateInterface} from "../../models"

// Types
import * as a from "../actions"
import { signOutSuccess } from "../../../authorization/store/actions"

const InitialGroupsState: GroupsStateInterface = {
    groupsFetching: false,
    groupCreating: false,
    groupCreated: false,
    groupDeleting: false,
    query: "",
    searching: false,
    groupsById: {},
    groupsIds: [],
};

const groupsReducer = createReducer(InitialGroupsState, {
    [getType(a.fetchGroupsAsync.request)]: state => ({
        ...state,
        groupsFetching: true,
    }),
    [getType(a.fetchGroupsAsync.success)]: (state, {payload}) => ({
        ...state,
        groupsById: payload.groupsById,
        groupsIds: payload.groupsIds,
        groupsFetching: false,
        searching: false,
    }),
    [getType(a.mergeGroups)]: (state, {payload}) => ({
        ...state,
        groupsById: {
            ...state.groupsById,
            ...payload.groupsById,
        },
        groupsIds: [
            ...state.groupsIds,
            ...payload.groupsIds,
        ],
    }),
    [getType(a.safeMergeGroups)]: (state, {payload}) => ({
        ...state,
        groupsById: {
            ...payload.groupsById,
            ...state.groupsById,
        },
    }),
    [getType(a.fetchGroupsAsync.failure)]: state => ({
        ...state,
        groupsFetching: false,
    }),
    [getType(a.createGroupAsync.request)]: state => ({
        ...state,
        groupCreating: true,
        groupCreated: false,
    }),
    [getType(a.createGroupAsync.success)]: (state, {payload}) => ({
        ...state,
        groupsById: {
            [payload.id]: payload,
            ...state.groupsById,
        },
        groupsIds: [
            payload.id,
            ...state.groupsIds,
        ],
        groupCreating: false,
        groupCreated: true,
    }),
    [getType(a.createGroupAsync.failure)]: state => ({
        ...state,
        groupCreating: false,
        groupCreated: false,
    }),
    [getType(a.deleteGroupAsync.request)]: state => ({
        ...state,
        groupDeleting: true,
    }),
    [getType(a.deleteGroupAsync.success)]: (state, {payload}) => {
        const newState = {
            ...state,
            groupsIds: state.groupsIds.filter(id => id !== payload),
            groupDeleting: false,
        };

        delete newState.groupsIds[payload];

        return newState
    },
    [getType(a.deleteGroupAsync.failure)]: state => ({
        ...state,
        groupDeleting: false,
    }),
    [getType(a.setGroupsSearchQuery)]: (state, {payload}) => ({
        ...state,
        query: payload.query,
    }),
    [getType(a.setGroupsSearching)]: state => ({
        ...state,
        searching: true,
    }),
    [getType(signOutSuccess)]: () => InitialGroupsState,
});

export default groupsReducer
