import styled from "styled-components"

// Component
import UnauthorizedSidebar from "./UnauthorizedSidebar";

// Models
import {WithoutClassNameProps} from "../../models"

export type AuthSidebarProps = {
    className?: string
    noSider?: boolean
}

const StyledSidebar = styled(UnauthorizedSidebar)`
    background: url("../../../static/assets/background/sidebar/bg.png") top center no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    padding: 30px;
    align-items: flex-center;
 
    .ant-layout-sider-children {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
    }
    
    .content {
        max-width: 300px;
        padding-bottom:300px
        
      
    }
    
    .title {
        color: ${({theme}) => theme.color.green};
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    
    .desc {
        color: ${({theme}) => theme.color.white};
        font-size: 14px;
        opacity: 0.8;
        line-height: 24px;
        margin-bottom: 20px;
    }
    
    .copy {
        color: ${({theme}) => theme.color.white};
        font-size: 14px;
        opacity: 0.3;
    }
    
    .live-demo-button {
      width: 245px;
    }

    .links {
        display: flex;
        flex-direction: column;
    }
    
    @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url("../../../static/assets/background/sidebar/bg@2x.png");
    }
    
    @media screen and (max-width: ${({theme}) => theme.breakpoints.lg}) {
        padding: 30px 15px;
    }
` as WithoutClassNameProps<AuthSidebarProps>

export default StyledSidebar;