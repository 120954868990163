import React, {useState} from "react"
import {Button, Input} from "antd"
import styled from "styled-components"
import {compose} from "redux"
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import useTranslation from 'next-translate/useTranslation'

// HOCs
import withUser, {WithUserProps} from "../../hoc/withUser"
import withUserEdit, {WithUserEditProps} from "../../modules/authorization/hoc/withUserEdit"

// Components
import UserAvatar from "../UserAvatar"

type Props = {
    className?: string
} & WithUserProps & WithUserEditProps

const EditComponent = (props: Props) => {
    const {className, user: {firstname, lastname, id}, editUser} = props
    const [isActive, setIsActive] = useState(false)
    const [editName, setEditName] = useState(preparedUserName())
    const { t } = useTranslation();

    function preparedUserName() {
        if (typeof lastname === "undefined" || lastname === null) {
            return firstname
        }

        return `${firstname} ${lastname}`
    }

    function getFirstNameAndLastNameFormEditname() {
        const [firstname, ...lastname] = editName.trim().split(" ")

        return {firstname, lastname: lastname.join(" ")}
    }

    function handleEditConfirm() {
        setIsActive(false)
        editUser({id, ...getFirstNameAndLastNameFormEditname()})
    }

    function handleEditButton() {
        setIsActive(true)
        setEditName(preparedUserName())
    }

    function getContent() {
        if (isActive) {
            return (
                <React.Fragment>
                    <Input
                        className="edit-input"
                        value={editName}
                        placeholder={t('common:type_name')}
                        onChange={e => setEditName(e.target.value)}
                    />
                    <Button
                        type="link"
                        icon={<CheckOutlined />}
                        className="check-button"
                        disabled={editName.trim().length < 2}
                        onClick={handleEditConfirm}
                    />
                    <Button
                        type="link"
                        icon={<CloseOutlined />}
                        className="close-button"
                        onClick={() => setIsActive(false)}
                    />
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <span className="user-name">
                    {preparedUserName()}
                </span>
                <Button
                    type="link"
                    icon={<EditOutlined />}
                    className="edit-button"
                    onClick={handleEditButton}
                />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <UserAvatar
                firstname={firstname}
                lastname={lastname}
                fontSize={28}
                size={75}
            />
            <div className={className}>
                {getContent()}
            </div>
        </React.Fragment>
    )
}

const Styled = styled(EditComponent)`
    margin-top: 5px;
    display: flex;
    
    .user-name {
        font-size: 20px;
        margin-left: 32px;
        color: ${({theme}) => theme.color.darkGray};
        font-weight: ${({theme}) => theme.fontWeight.semiBold};
    }
    
    .edit-input {
        border: 0;
        border-bottom: 1px solid ${({theme}) => theme.color.blue};
        color: ${({theme}) => theme.color.darkGray};
        border-radius: 0;
        margin-right: 5px;
        margin-left: 45px;
    }
    
    .edit-button {
        color: ${({theme}) => theme.color.blue};
        transition: 0ms;
    }
    
    .check-button[disabled] {
        color: rgba(0, 0, 0, 0.25);
    }
    
    .check-button {
        color: ${({theme}) => theme.color.green};
        transition: 0ms;
    }
    
    .close-button {
        color: ${({theme}) => theme.color.red};
        transition: 0ms;
    }
`

export default compose(
    withUser,
    withUserEdit,
)(Styled)