import {createGlobalStyle} from "styled-components"
import Theme from "../../theme/Theme"

export default createGlobalStyle`
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
    body {
      font-family: "Open Sans", sans-serif;
      font-variant: normal;
      overflow: hidden;
    }
    h1, h2, h3, h4, h5, h6, p {
      margin-bottom: 0;
      line-height: 1.4;
      color: ${Theme.color.darkGray}
    }
    img {
        max-width: 100%;
    }
    button {
      border: 0;
      outline: 0;
      background: transparent;
      cursor: pointer;
    }
    #__next {
      min-height: 100%;
    }
    .ant-layout {
      background-color: #fafafa;
    }
    .ant-layout-content {
    height:92%
    }
    .ant-layout-sider-children .logo {
      height: 32px;
      background: rgba(255, 255, 255, 0.2);
      margin: 16px;
    }
    .ant-layout-sider-zero-width-trigger {
        z-index: 99;
    }
`
