import React from "react"
import getConfig from "next/config"

interface Props {
    height?: number
    width?: number
    className?: string
    white?: boolean
}

const Logo: React.FC<Props> = props =>  {
    const {className = null, width = "auto", height = 40, white = false} = props;
    const {
        publicRuntimeConfig: {THEME},
    } = getConfig();
    const isUpolife = THEME === "upolife"

    const getSrc = () => {
        if (isUpolife) {
            return white ? "/static/upolife/logo-white.svg" : "/static/upolife/logo.svg"
        }

        return "/static/cardiolyse/logo.svg"
    }

    const getAlt = () => isUpolife ? "Upolife logo" : "Cardiolyse logo"

    return (
        <img
             width={width}
             height={height}
             className={className}
             alt={getAlt()}
             src={getSrc()}
        />
    )
}

export default Logo