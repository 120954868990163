import {createReducer, getType} from "typesafe-actions"

// Models
import {Sex, UserIdentifier} from "../../../../constants"
import {PatientsFactoryStateType} from "../../models"

// Actions
import * as a from "../actions"
import { signOutSuccess } from "../../../authorization/store/actions"

const InitialPatientsFactoryState: PatientsFactoryStateType = {
    // Common
    createModalVisible: false,
    editModalVisible: false,
    sexTabs: [Sex.MALE, Sex.OTHER, Sex.FEMALE],
    selectedSex: Sex.OTHER,
    identifierTabs: [UserIdentifier.PHONE, UserIdentifier.EMAIL],
    selectedIdentifier: UserIdentifier.PHONE,
    selectedCountryCode: "UA",
    // Create
    creatingPatient: false,
    creatingPatientError: null,
    // Edit
    editingPatient: false,
    editingPatientError: null
};

const createPatientReducer = createReducer(InitialPatientsFactoryState, {
    [getType(a.setCreateModalVisible)]: (state, {payload}) => ({
        ...state,
        createModalVisible: payload.visible,
        creatingPatientError: null,
    }),
    [getType(a.createPatientAsync.request)]: state => ({
        ...state,
        creatingPatient: true,
        creatingPatientError: null,
    }),
    [getType(a.createPatientAsync.success)]: state => ({
        ...state,
        creatingPatient: false,
        createModalVisible: false,
        creatingPatientError: null,
    }),
    [getType(a.createPatientAsync.failure)]: (state, {payload}) => ({
        ...state,
        creatingPatient: false,
        creatingPatientError: payload,
    }),
    [getType(a.setEditModalVisible)]: (state, {payload}) => ({
        ...state,
        editModalVisible: payload.visible,
        editingPatientError: null,
    }),
    [getType(a.editPatientAsync.request)]: state => ({
        ...state,
        editingPatient: true,
        editingPatientError: null,
    }),
    [getType(a.editPatientAsync.success)]: state => ({
        ...state,
        editingPatient: false,
        editModalVisible: false,
        editingPatientError: null,
    }),
    [getType(a.editPatientAsync.failure)]: (state, {payload}) => ({
        ...state,
        editingPatient: false,
        editingPatientError: payload,
    }),
    [getType(a.selectSex)]: (state, {payload}) => ({
        ...state,
        selectedSex: payload.sex,
    }),
    [getType(a.selectIdentifier)]: (state, {payload}) => ({
        ...state,
        selectedIdentifier: payload.identifier,
    }),
    [getType(a.selectCountryCode)]: (state, {payload}) => ({
        ...state,
        selectedCountryCode: payload.code,
    }),
    [getType(signOutSuccess)]: () => InitialPatientsFactoryState,
});

export default createPatientReducer
