import {Routes} from "../../../constants"

export interface MenuItemInterface {
    key: string
    href: string
    text: string
    target: "_self" | "_blank"
    icon: string
    activeIcon: string
}

export const menuItems: Array<MenuItemInterface> = [
    {
        key: Routes.PATIENTS,
        href: Routes.PATIENTS,
        text: "Patients",
        target: "_self",
        icon: "/static/assets/menu-icons/patients",
        activeIcon: "/static/assets/menu-icons/patients_blue"
    },
    {
        key: Routes.GROUPS,
        href: Routes.GROUPS,
        text: "Groups",
        target: "_self",
        icon: "/static/assets/menu-icons/groups",
        activeIcon: "/static/assets/menu-icons/groups_blue",
    },
    {
        key: Routes.HISTORY,
        href: Routes.HISTORY,
        text: "Records",
        target: "_self",
        icon: "/static/assets/menu-icons/history",
        activeIcon: "/static/assets/menu-icons/history_blue"
    },
];
