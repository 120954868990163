import React, {useEffect, useState} from "react"
import {Button, Form, message} from "antd"
import styled from "styled-components"
import {Field, Formik} from "formik"
import {compose} from "redux"
import useTranslation from 'next-translate/useTranslation'

// HOCs
import withUtils, {WithUtilsProps} from "../../hoc/withUtils"
import withChangePassword, {WithChangePasswordProps} from "../../modules/authorization/hoc/withChangePassword"

// Hooks
import usePrevious from "../../hooks/usePrevious"

// Components
import {Input} from "../createFormItem"

type Props = {
    className?: string
} & WithUtilsProps & WithChangePasswordProps

const ChangePasswordComponent = (props: Props) => {
    const {
        className,
        utils: {
            validateConfirmPassword,
            validatePassword,
        },
        userPasswordChanging,
        userPasswordChanged,
        changeUserPassword,
    } = props
    const [isActive, setIsActive] = useState(false)
    const previousPasswordResetSending = usePrevious(userPasswordChanging)
    const { t } = useTranslation();

    useEffect(() => {
        if (typeof previousPasswordResetSending !== "undefined" && previousPasswordResetSending !== userPasswordChanging && userPasswordChanged) {
            message.success("Password changed")
            setIsActive(false)
        }
    }, [userPasswordChanging, userPasswordChanged])

    const handleChangeButton = values => {
        if (isActive) {
            changeUserPassword(values.password)
            return
        }

        setIsActive(true)
    }

    const handleCancel = () => {
        setIsActive(false)
    }

    return (
        <div className={className}>
            <Formik
                onSubmit={handleChangeButton}
                initialValues={{password: "", confirm: ""}}
            >
                {({handleSubmit, isValid, values}) => (
                    <Form onFinish={handleSubmit}>
                        {isActive && (
                            <React.Fragment>
                                <Field
                                    name="password"
                                    type="password"
                                    placeholder={t('common:auth.new_password')}
                                    validate={validatePassword}
                                    component={Input}
                                />
                                <Field
                                    name="confirm"
                                    type="password"
                                    placeholder={t('common:auth.confirm_password')}
                                    validate={value => validateConfirmPassword(value, values.password)}
                                    component={Input}
                                />
                            </React.Fragment>
                        )}
                        <div className="buttons-container">
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isActive && userPasswordChanging}
                                disabled={isActive && isValid === false}
                            >
                                {isActive ? t('common:save') : t('common:auth.change_password')}
                            </Button>
                            {isActive && (
                                <Button
                                    disabled={userPasswordChanging}
                                    className="cancel-button"
                                    onClick={handleCancel}
                                >
                                    {t('common:cancel')}
                                </Button>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

const Styled = styled(ChangePasswordComponent)`
    margin-top: 15px;
    
    .buttons-container {
        display: flex;
        justify-content: center;
    }
    
    .cancel-button {
        margin-left: 15px;
    }
    
    .ant-form-item-children-icon {
        display: none;
    }
`

export default compose(
    withChangePassword,
    withUtils,
)(Styled)